import {useSiteMetadata} from "../hooks";
import Layout from "../components/Layout";
import React from "react";
import TextPolicyPage from "../components/TextPolicyPage";
import {PRIVACY_POLICY} from "../constants/privacy-policy";
import {graphql, useStaticQuery} from "gatsby";

const PrivacyPolicyTemplate = ({ location }) => {
    const { subtitle: siteSubtitle } = useSiteMetadata();
    const { bg } = useStaticQuery(graphql`
      query {
          bg: allFile(filter: { sourceInstanceName: { eq: "preview" } name: { eq: "privacy-policy" } }) {
              nodes {
                  name
                  publicURL
              }
          }
      }
  `);


  return (
        <Layout title="Privacy Policy"
                darkBreadcrumbs
                description={siteSubtitle}
                location={location}
                socialImage={bg.nodes[0].publicURL}>
           <TextPolicyPage page={PRIVACY_POLICY}/>
        </Layout>
    );
};

export default PrivacyPolicyTemplate;

