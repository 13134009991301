import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import styles from './TextPolicyText.module.scss';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';

const TextPolicyPage = ({page}) => {
  const Subtitle = styled.p`
  text-align: ${({centered, subheading}) => centered || subheading ? 'left' : 'left'};
  font-weight: ${({bold, subheading}) => bold || subheading ? '500' : '500'};
  color: ${({bold}) => bold ? '#798294' : '#798294'};
  font-size: ${({subheading}) => subheading ? '22px' :'22px'};
  margin-bottom: ${({styles}) => styles ? styles.marginBottom : '24px'};
  margin-top: 0;
  padding: 0;
  line-height: ${(subheading) => subheading ? '32px' :'32px'};
  @media (max-width: 960px) {
     font-size: ${(subheading) => subheading ? '18px' :'18px'};
     line-height: 24px;
     ${({mediaStyles}) => mediaStyles && {...mediaStyles}},   
  }
  `
  const cookies = {
    preferences: ['isBannerShow','TOGGLE_ADMIN_SIDEBAR','TOGGLE_SOLUTION_SIDEBAR','redirect','@@scroll#','keyval-store#keyval'],
    statistics: ['_ga', '_gid', '_gat_UA-47512871-10', '_gat_UA-47512871-2', '_ga_KBN5VJ1LHK', 'collect', '_pk_id', '_pk_ref', '_pk_ses', '_pk_cvar', '_pk_hsr', '_pk_testcookie'],
    thirdParty: ['__cfduid'],
  };
  const initialCookie = {functional: true, preferences: true, statistics: true, thirdParty: true};
  const [customizeCookies, setCustomizeCookies] = useState(typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('isCookiesAccepted')) : initialCookie);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('isCookiesAccepted', JSON.stringify(customizeCookies));
    }
  }, [customizeCookies]);

  const handleCookies = (event) => {
    if (customizeCookies[event.target.name]) {
      cookies[event.target.name].forEach((cookieName) => {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
      });
    }
    setCustomizeCookies({ ...customizeCookies, [event.target.name]: !customizeCookies[event.target.name] });
    window.location.pathname === '/cookies-policy' &&  window.location.reload()
  };

  return (
    <div className={styles['policy']}>
      <div className={styles['container']}>
        <div className={styles['section']}>
          <Typography variant={'h1'} color={'secondary'} align={'center'}>
            {page.HEADER}
          </Typography>
          <Typography variant={'h4'}>
            {page.SUBHEADER}
          </Typography>
          {page.MAIN_TEXT.map((line, ind) => (
            <Typography dangerouslySetInnerHTML={{__html: line}} key={line}/>
          ))}
        </div>
        {Object.keys(page.CONTENT).map((key, ind) => (
          <div key={key} className={styles['section']}>
            <Typography color={'secondary'} align={'center'} variant={'h3'}
                        style={page.CONTENT[key].heading.style && page.CONTENT[key].heading.style}>
              {page.CONTENT[key].heading.text ? page.CONTENT[key].heading.text : page.CONTENT[key].heading}
            </Typography>
            {page.CONTENT[key].text.map((line, i, array) => {
              if (typeof line === 'string') {
                return (<Typography
                  key={i}
                  dangerouslySetInnerHTML={{__html: line}}
                  style={{
                    marginBottom:
                      array[i + 1] && Array.isArray(array[i + 1])
                        ? '12px'
                        : '24px',
                  }}
                  />)
              }
              if (Array.isArray(line)) {
                return (<ul key={i}>
                  {line.map((item, ind) => (
                    <li key={ind}>
                      <div className={styles['bullet-container']}>
                        <div className={styles['bullet']}/>
                      </div>
                      <Typography dangerouslySetInnerHTML={{__html: item}} />
                    </li>
                  ))}
                </ul>)
              }
              if (line.table) {
                return (
                  <div className={styles['table-container']}
                       key={i}>
                    <table style={line.style && line.style}>
                      <thead>
                      <tr>
                        {
                          line.content.head.map((cell, ind) => (
                            <th key={ind}>
                              <Typography color={'secondary'} variant={'body2'}
                                          align={'left'}>{cell}</Typography>
                            </th>
                          ))
                        }
                      </tr>
                      </thead>
                      <tbody>
                      {line.content.body.map((row, ind) => (
                        <tr key={ind}>
                          {
                            row.map((cell, i) => (
                              <td key={i}>
                                {
                                  typeof cell === 'string' ?
                                    <Typography variant={'body2'}
                                                dangerouslySetInnerHTML={{__html: cell}}/> :
                                    cell.map((line, ind) => (
                                      <Typography variant={'body2'}
                                                  key={ind}
                                                  dangerouslySetInnerHTML={{__html: line}}/>
                                    ))
                                }
                              </td>
                            ))
                          }
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                )
              }
              if (line.styles) {
                return (<div className={styles['wrapperSubheading']}> 
                  <div className={line.styles.includes('subheading') && styles['blockWithSwitch']}>
                 <Subtitle key={line.text}
                    bold={line.styles.includes('bold')}
                    subheading={line.styles.includes('subheading')}
                    styles={line.style}
                    mediaStyles={line.mediaStyle}
                    centered={line.styles.includes('centered')}>{line.text}
                  </Subtitle>
                  {line.styles.includes('subheading') && <Switch
                    disabled={line.categoryCookies === 'functional' ? true : false}
                    checked={customizeCookies !== null ?  customizeCookies[line.categoryCookies] : true}
                    name={line.categoryCookies}
                    size='medium'
                    onChange={handleCookies}
                    />}
                  </div>
                  {customizeCookies !== null && !customizeCookies[line.categoryCookies] && <p className={styles['warning']}>  {line.warningText}</p>}
                </div>
                )
                // return (<Typography
                //     key={line.text}
                //     className={classNames({
                //       [styles['bold']]: line.styles.includes('bold'),
                //       [styles['subheading']]: line.styles.includes('subheading'),
                //       [styles['centered']]: line.styles.includes('centered')
                //     })}
                //     style={line.style && {...line.style}}
                //     variant={line.styles.includes('subheading') ? 'h4' : 'subtitle1'}>{line.text}</Typography>
                // )
              }
            })}
          </div>
        ))}
      </div>
    </div>
  )
}

export default TextPolicyPage;